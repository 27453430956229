import { Link as FUILink } from "@fluentui/react-components";

function Link(props) {

	const onClick = (e) => {
		if (e.metaKey || e.ctrlKey)
			return;

		// prevent full page reload
		e.preventDefault();

		changeUrl(props.href);
	  };

	  return (
		<FUILink onClick={onClick} {...props}>
		  {props.children}
		</FUILink>
	  );
}

export function changeUrl(href)
{
	// update url
	window.history.pushState({}, "", href);

	// communicate to Routes that URL has changed
	const navEvent = new PopStateEvent('popstate');
	window.dispatchEvent(navEvent);
}

export default Link;