import React, { useContext, useState } from 'react';
import { ApiContext } from '../../api/ApiContext';

import {
	Dialog,
	DialogSurface,
	DialogTitle,
	DialogContent,
	DialogActions,
	DialogTrigger,
	DialogBody,
	Button,
	Select,
	Label,
	Input,
	Spinner,
  } from "@fluentui/react-components";
import styled from 'styled-components';

var idRef = React.createRef();
var nameRef = React.createRef();
var passRef = React.createRef();
var urlRef = React.createRef();
var typeRef = React.createRef();

export function UserModal(props) {

	const api = useContext(ApiContext);

	const [working, setWorking] = useState(false);

	function clear()
	{
		setFormData(0, '', '', '', false);
	}

	function setFormData(id, name, pass, url, type)
	{
		idRef.current.value = id;
		nameRef.current.value = name;
		passRef.current.value = pass;
		urlRef.current.value = url;
		typeRef.current.value = type;
	}

	function close()
	{
		clear();
		props.close();
	}

	function setUser(e)
	{
		e.target.disabled = true;
		setWorking(true);
		api.setUser(
			idRef.current.value,
			nameRef.current.value,
			passRef.current.value,
			urlRef.current.value,
			typeRef.current.value,
			(result) => {
				setWorking(false);
				close();
				props.refresh();
			}, () => {

			}
		);
	}

	var spinner = null;
	if(working)
		spinner = <Spinner appearance="inverted" size="tiny"/>

	return (
		<Dialog open={props.open === true} onOpenChange={(event, data) => close()}>
			<DialogSurface>
				<DialogBody>
					<DialogTitle>Přidat uživatel</DialogTitle>
					<DialogContent>
						<Form>
							<input type="hidden" ref={idRef}/>
							<div>
								<Label>Jméno:</Label>
								<Input type="text" ref={nameRef}/>
							</div>
							<div>
								<Label>Heslo:</Label>
								<Input type="text" ref={passRef}/>
							</div>
							<div>
								<Label>URL:</Label>
								<Input type="text" ref={urlRef}/>
							</div>
							<div>
								<Label>Typ:</Label>
								<div>
									<Select ref={typeRef}>
										<option value="false">Uživatel</option>
										<option value="true">Admin</option>
									</Select>
								</div>
							</div>
						</Form>
					</DialogContent>
					<DialogActions>
						<DialogTrigger disableButtonEnhancement>
							<Button appearance="secondary">Zavřít</Button>
						</DialogTrigger>
						<Button onClick={setUser} appearance="primary" >{spinner}Uložit</Button>
					</DialogActions>
				</DialogBody>
			</DialogSurface>
		</Dialog>
	);
}

const Form = styled.form`
	display: grid;
	gap: 12px;

	div {
		display: grid;
	}
`;
