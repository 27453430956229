import React from 'react';
import { changeUrl } from '../../pages/Router/Link';
import { FileIcon } from './FilesIcon';
import { FileUtilities } from './FileUtilities';

import {
	Link,
	Button
} from "@fluentui/react-components";

import {
	DataGridBody,
	DataGridRow,
	DataGrid,
	DataGridHeader,
	DataGridHeaderCell,
	DataGridCell,
	TableCellLayout,
	TableCellActions,
	createTableColumn
} from "@fluentui/react-components/unstable";

import {
	DismissFilled
} from "@fluentui/react-icons";
import styled from 'styled-components';
import { device } from '../../MediaDetector';

export function FilesList(props) {

	const state = props.state;
	const setState = props.setState;
	const refresh = props.refresh;

	const items = [];

	for (const key in state.uploadingFiles) {
		const element = state.uploadingFiles[key];
		items.push({
			name: element.name,
			path: "",
			icon: "upload",
			uploaded: "",
			size: Math.round(element.progress) + "%",
			type: "upload",
			delete: (e) => {
				element.abort();
			}
		});
	}

	for (const key in state.folderContent) {
		const element = state.folderContent[key];
		var path = `${state.folderPath}${element.name}`;
		items.push({
			name: element.name,
			path: path,
			icon: element.mime,
			uploaded: "",
			size: "",
			type: element.type,
		});
	}

	const columns = [
		createTableColumn({
			columnId: "name",
			compare: (a, b) => {
				if (a.type === "folder" && b.type === "file")
					return -1;
				else if (a.type === "file" && b.type === "folder")
					return 1;
				else
					return a.name.localeCompare(b.name);
			},
			renderHeaderCell: () => {
				return <DataGridHeaderCell>Jméno</DataGridHeaderCell>;
			},
			renderCell: (item) => {
				if(item.type === "folder")
				{
					return (
						<DataGridCell>
							<TableCellLayout media={<FileIcon icon={"folder"}/>}>
								<Link as='button' onClick={(e) => {
									e.stopPropagation();
									changeUrl(`${state.urlRoot}/${item.path}`);
								}}>{item.name}</Link>
							</TableCellLayout>
						</DataGridCell>
					);
				}
				else if(item.type === "upload")
				{
					return (
						<DataGridCell>
							<TableCellLayout media={<FileIcon icon={item.icon}/>}>
								{item.name}
							</TableCellLayout>
						</DataGridCell>
					);
				}
				else
				{
					return (
						<DataGridCell>
							<TableCellLayout media={<FileIcon icon={item.icon}/>}>
								<Link as='button' onClick={(e) =>  {e.stopPropagation(); setState(s => ({ ...s, openedMedia: item.path }))}}>{item.name}</Link>
							</TableCellLayout>
						</DataGridCell>
					);
				}
			},
		}),
		createTableColumn({
			columnId: "uploaded",
			compare: (a, b) => {
				return a.uploaded.localeCompare(b.uploaded);
			},
			renderHeaderCell: () => {
				return <HideMobileDataGridHeaderCell>Nahráno</HideMobileDataGridHeaderCell>;
			},
			renderCell: (item) => {
				return (
					<HideMobileDataGridCell>
						{item.uploaded}
					</HideMobileDataGridCell>
				);
			},
		}),
		createTableColumn({
			columnId: "size",
			compare: (a, b) => {
				return a.size.localeCompare(b.size);
			},
			renderHeaderCell: () => {
				return <HideMobileDataGridHeaderCell>Velikost</HideMobileDataGridHeaderCell>;
			},
			renderCell: (item) => {
				if(item.type === "upload")
				{
					return (
						<StyledUploadDataGridCell>
							{item.size}
						</StyledUploadDataGridCell>
					);
				}
				else
				{
					return (
						<HideMobileDataGridCell>
							{item.size}
						</HideMobileDataGridCell>
					);
				}

			},
		}),
		createTableColumn({
			columnId: "actions",
			compare: null,
			renderHeaderCell: () => {
				return <StyledDataGridHeaderCell/>;
			},
			renderCell: (item) => {
				if(item.type === "upload")
				{
					return (
						<StyledDataGridCell>
							<StyledTableCellActions>
								<Button onClick={function(e){e.stopPropagation(); item.delete();}} icon={<DismissFilled/>} appearance="subtle"/>
							</StyledTableCellActions>
						</StyledDataGridCell>
					);
				}
				else
				{
					return (
						<StyledDataGridCell>
							<StyledTableCellActions>
								<FileUtilities path={item.path} refresh={refresh} />
							</StyledTableCellActions>
						</StyledDataGridCell>
					);
				}
			},
		}),
	];

	return (
		<StlyedDataGrid
			items={items}
			columns={columns}
			sortable
			selectionAppearance="none"
			selectionMode="multiselect"
			getRowId={(item) => item.key}
			selectedItems={state.selectedItems}
			onSelectionChange={(e, data) => { setState(s => ({...s})); }}
		>
			<DataGridHeader>
				<DataGridRow selectionCell={{ "aria-label": "Select all rows" }}>
					{({ renderHeaderCell }) => (renderHeaderCell())}
				</DataGridRow>
			</DataGridHeader>

			<DataGridBody>
				{({ item, rowId }) => (
					<DataGridRow key={rowId} selectionCell={{ "aria-label": "Select row" }}>
						{({ renderCell }) => (renderCell(item))}
					</DataGridRow>
				)}
			</DataGridBody>
		</StlyedDataGrid>
	);
}

const StlyedDataGrid = styled(DataGrid)`
	word-break: break-word;
`;

const StyledDataGridHeaderCell = styled(DataGridHeaderCell)`
	max-width: 110px;
`;

const StyledUploadDataGridCell = styled(DataGridCell)`
	max-width: 35px;
	justify-content: center;
`;

const StyledDataGridCell = styled(DataGridCell)`
	max-width: 110px;
	justify-content: center;
`;

const StyledTableCellActions = styled(TableCellActions)`
	right: unset;
	opacity: 1;
	@media ${device.laptop} {
		opacity: 0;
	}
`;


const HideMobileDataGridHeaderCell = styled(DataGridHeaderCell)`
	display: none;
	@media ${device.laptop} {
		/*display: flex;*/
	}
`;

const HideMobileDataGridCell = styled(DataGridCell)`
	display: none;
	@media ${device.laptop} {
		/*display: flex;*/
	}
`;
