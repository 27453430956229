import React, { useContext, useState }  from 'react';
import { ApiFilesContext } from '../../api/ApiFilesContext';
import { changeUrl } from '../../pages/Router/Link';
import { Perm } from '../../pages/Perm';
import { ShareModal } from '../Modals/ShareModal';

import {
	Button,
	Toolbar,
	ToolbarDivider,
	Label,
  } from "@fluentui/react-components";

import {
	AppsListFilled,
	GridFilled,
	ArrowDownloadFilled,
	ArrowUploadFilled,
	ArrowUpFilled,
	ArrowClockwiseFilled,
	FolderAddFilled,
	ShareAndroidFilled,
} from "@fluentui/react-icons";
import styled from 'styled-components';
import { device } from '../../MediaDetector';

var uploadRef = React.createRef();

export function FilesHeader(props) {

	const api = useContext(ApiFilesContext);

	const state = props.state;
	const setState = props.setState;
	const refresh = props.refresh;

	const [sharePath, shareFile] = useState(null);

	function uploadFiles() {
		var files = uploadRef.current.files;
		for (const key in files) {
			const file = files[key];
			if(typeof file === "object")
			{
				setState(s => {
					const uf = { ...s.uploadingFiles };
					uf[file.name] = {
						name: file.name,
						progress: 0,
						abort: null,
					};
					return { ...s, uploadingFiles: uf };
				});

				api.uploadFiles(encodeURIComponent(state.folderPath), file, (loaded, total, abort) => {
					setState(s => {
						const uf = { ...s.uploadingFiles };
						uf[file.name] = {
							name: file.name,
							progress: (loaded / total) * 100,
							abort: abort,
						};
						return { ...s, uploadingFiles: uf };
					});
				}, (status, response) => {
					setState(s => {
						const uf = { ...s.uploadingFiles };
						delete uf[file.name];
						return { ...s, uploadingFiles: uf };
					});
					refresh(true);
				}, () => {

				});
			}
		}
		uploadRef.current.value = null;
		console.log(uploadRef.current.files);
	}

	function downloadFolder() {
		var link = encodeURIComponent(state.folderPath);
		setState(s => ({ ...s, openedMedia: link }))
	}

	function grid()
	{
		setState(s => ({...s, displayTypeGrid: true}));
	}

	function list()
	{
		setState(s => ({...s, displayTypeGrid: false}));
	}

	function up()
	{
		var oldPath = state.folderPath;
		if(oldPath.endsWith('/'))
			oldPath = oldPath.substring(0, oldPath.length - 1);

		var oldPathSplit = oldPath.split("/");
		oldPathSplit.pop();

		var newPath = oldPathSplit.join('/');
		if(newPath)
			changeUrl(`${state.urlRoot}/${newPath}`);
		else
			changeUrl(`${state.urlRoot}`);
	}

	function newFolder()
	{
		var newFolderText = "Nová složka";
		api.createFolder(encodeURIComponent(state.folderPath + newFolderText), (result) => {
			refresh();
		}, () => {

		});
	}

	return (
		<>
			<h2>Soubory</h2>
			<StyledToolbars>
				<StyledToolbar>
					<Button onClick={()=>{up()}} icon={<ArrowUpFilled/>} appearance="subtle" />
					<Button onClick={()=>{refresh();}} icon={<ArrowClockwiseFilled/>} appearance="subtle" />

					<Perm subject="newFolder">
						<ToolbarDivider />
						<Button onClick={()=>{newFolder();}} icon={<FolderAddFilled/>} appearance="subtle" />
					</Perm>

					<Perm subject="shareFile">
						<ToolbarDivider />
						<Button onClick={(e) => {shareFile(state.folderPath)}} icon={<ShareAndroidFilled />} appearance="subtle" />
						<ShareModal path={sharePath} close={()=>{shareFile(null)}}></ShareModal>
					</Perm>
				</StyledToolbar>
				<StyledToolbar>
					<Perm subject="uploadaFile">
						<HideMobile>
							<ToolbarDivider />
						</HideMobile>
						<Button onClick={(e) => {uploadRef.current.click()}} icon={<ArrowUploadFilled/>}>
							Nahrát soubor
						</Button>
						<input ref={uploadRef} type="file" multiple style={{display: 'none'}} onChange={()=>{uploadFiles()}}/>
					</Perm>

					<Button onClick={()=>{downloadFolder()}} icon={<ArrowDownloadFilled/>} appearance="primary">
						{state.selectedItems.size === 0 || true? "Stáhnout vše" : "Stáhnout vybrané"}
					</Button>
				</StyledToolbar>
				<StyledToolbarEnd>
					<HideMobile>
						<Label>Zobrazení:</Label>
					</HideMobile>
					<Button onClick={()=>{list()}} icon={<AppsListFilled/>}/>
					<Button onClick={()=>{grid()}} icon={<GridFilled/>}/>
				</StyledToolbarEnd>
			</StyledToolbars>
		</>
	);
}

const StyledToolbars = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;

	@media ${device.laptop} {
		flex-direction: row;
	}
`;

const StyledToolbar = styled(Toolbar)`
	align-items: center;
	justify-content: space-between;
	@media ${device.laptop} {
		justify-content: center;
		padding-right: 0px;
	}
`;

const StyledToolbarEnd = styled(StyledToolbar)`
	justify-content: center;
	@media ${device.laptop} {
		margin-left: auto;
	}
`;

const HideMobile = styled.div`
	display: none;
	@media ${device.laptop} {
		display: inline-block;
	}
`;