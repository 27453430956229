import React, { useContext } from 'react';
import { ApiContext } from '../../api/ApiContext';


import { Button, Link } from "@fluentui/react-components";
import { DeleteFilled } from "@fluentui/react-icons";

import {
	TableCell,
	TableRow,
  } from "@fluentui/react-components/unstable";
import styled from 'styled-components';

export function UserRow(props) {

	const api = useContext(ApiContext);

	function deleteUser()
	{
		var ok = window.confirm('Opravdu smazat? Nedojde ke smazání dat na disku.');
		if(ok)
		{
			api.removeUser(props.user.id, (result) => {
				props.refresh();
			}, () => {

			});
		}
	}

	return (
		<TableRow>
			<TableCell><Link as="button">{props.user.name}</Link></TableCell>
			<TableCell>{props.user.isAdmin ? "ano" : "ne"}</TableCell>
			<TableCellURL title={props.user.defaultFolder}>{props.user.defaultFolder}</TableCellURL>
			<TableCell>
				<Button onClick={() => {deleteUser();}} icon={<DeleteFilled />} appearance="subtle"/>
			</TableCell>
		</TableRow>
	);
}

const TableCellURL = styled(TableCell)`
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	max-width: 200px;
	direction: rtl;
	text-align: left;
`;