import React from 'react';

import {
	Select,
	Label,
  } from "@fluentui/react-components";
import styled from 'styled-components';

export function Settings(props) {

	return (
		<div>
			<h2>Nastavení</h2>
			<SettingsContent>
				<Label>Skin:</Label>
				<Select>
					<option value="dark">Tmavý</option>
					<option value="light">Světlý</option>
				</Select>
			</SettingsContent>
		</div>
	);
}

const SettingsContent = styled.div`
	width: 300px;
	display: flex;
	flex-direction: column;
`;