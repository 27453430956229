import React from 'react';

class ApiFiles {

	api = null;
	permalink = null;
	editable = null;

	getfiles(link, callback, errorCallback)
	{
		if(this.permalink)
			this.api.getSharedFiles(this.permalink, link, (result) => {
				this.editable = result.editable;
				callback(result.files);
			}, () => {

			});
		else
		{
			this.editable = null;
			this.api.getfiles(link, callback, errorCallback);
		}
	}

	deletefiles(link, callback, errorCallback)
	{
		if(this.permalink)
			this.api.deleteSharedFile(this.permalink, link, callback, errorCallback);
		else
			this.api.deletefiles(link, callback, errorCallback);
	}

	createFolder(link, callback, errorCallback)
	{
		if(this.permalink)
			this.api.createSharedFolder(this.permalink, link, callback, errorCallback);
		else
			this.api.createFolder(link, callback, errorCallback);
	}

	moveFileOrFolder(link, newLink, callback, errorCallback)
	{
		if(this.permalink)
			this.api.moveSharedFile(this.permalink, link, newLink, callback, errorCallback);
		else
			this.api.moveFileOrFolder(link, newLink, callback, errorCallback);
	}

	getFileDownloadLink(link)
	{
		if(this.permalink)
			return this.api.getSharedDownloadLink(this.permalink, link);
		else
			return this.api.getFileDownloadLink(link);
	}

	getFileThumbLink(link)
	{
		if(this.permalink)
			return this.api.getSharedThumbLink(this.permalink, link);
		else
			return this.api.getFileThumbLink(link);
	}

	uploadFiles(link, file, progress, callback, errorCallback)
	{
		if(this.permalink)
			this.api.uploadSharedFile(this.permalink, link, file, progress, callback, errorCallback);
		else
			this.api.uploadFiles(link, file, progress, callback, errorCallback);
	}
}

export const ApiFilesContext = React.createContext(new ApiFiles(null, null));
export const ApiFilesContextProvider = ApiFilesContext.Provider;