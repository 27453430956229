import { useEffect, useState } from 'react';

function Route(props) {
	const [currentPath, setCurrentPath] = useState(window.location.pathname);

	useEffect(() => {
		// define callback as separate function so it can be removed later with cleanup function
		const onLocationChange = () => {
			// update path state to current window URL
			setCurrentPath(window.location.pathname);
		}

		// listen for popstate event
		window.addEventListener('popstate', onLocationChange);

		// clean up event listener
		return () => {
			window.removeEventListener('popstate', onLocationChange)
		};
	}, [])

	var ret = props.children ?? true;

	if(props.not)
	{
		if(props.path)
			return currentPath === props.path ? null : ret;
		else if(props.pathStart)
			return currentPath.startsWith(props.pathStart) ? null : ret;
		else
			return null;
	}
	else
	{
		if(props.path)
			return currentPath === props.path ? ret : null;
		else if(props.pathStart)
			return currentPath.startsWith(props.pathStart) ? ret : null;
		else
			return ret;
	}
}

export default Route