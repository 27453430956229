import React, { useContext, useEffect, useState } from 'react';
import { ApiFilesContext } from '../../api/ApiFilesContext';
import styled from 'styled-components';

import {
	Dialog,
	DialogSurface,
	DialogContent,
	DialogActions,
	DialogTrigger,
	DialogBody,
	DialogTitle,
	Button,
	Spinner,
	Image,
} from "@fluentui/react-components";

import {
	DismissFilled,
	ChevronLeftFilled,
	ChevronRightFilled,
	ArrowDownloadFilled,
	ArrowExpand24Filled
} from "@fluentui/react-icons";
import useMediaDetector, { device } from '../../MediaDetector';

export function MediaModal(props) {

	const state = props.state;
	const setState = props.setState;

	const isTablet = useMediaDetector(device.tablet);

	function download(download) {
		let a = document.createElement("a");
		a.href = api.getFileDownloadLink(encodeURIComponent(state.openedMedia));
		a.target = '_blank';

		if(download)
			a.download = download;

		document.body.appendChild(a);
		a.onclick = (e) => {
			document.body.removeChild(e.target);
		};
		a.click();
	}

	var imageTypes = ["bmp", "gif", "jpeg", "png"];
	function isImage(file) {
		return file.mime && imageTypes.indexOf(file.mime.split("/")[1]) !== -1;
	}

	const api = useContext(ApiFilesContext);

	const [working, setWorking] = useState(true);
	var spinner = null;
	if(working)
		spinner = <StyledSpinner size="huge"/>

	if(state.openedMedia !== null)
	{
		var beforePath = "";
		var afterPath = "";
		var isSelectedImage = false;
		var name = "";

		var images = state.folderContent.filter(file => file.type === "file" && isImage(file));
		for (const i in images) {
			const file = images[i];
			if (state.openedMedia.endsWith(file.name))
			{
				isSelectedImage = true;
				name = file.name;
				var nextI = parseInt(i) + 1;
				if(images.length > nextI)
					afterPath = `${state.folderPath}${images[nextI].name}`;
				break;
			}
			else
			{
				beforePath = `${state.folderPath}${file.name}`;
			}
		}

		if(isSelectedImage)
		{
			return (
				<Dialog open={true} onOpenChange={(event, data) => { setState(s =>({ ...s, openedMedia: null})) }}>
					<StyledDialogSurface>
						<DialogBody>
							<DialogTitle action={
								<DialogTrigger>
										<Button icon={<DismissFilled/>} size={isTablet ? "medium" : "large"}/>
								</DialogTrigger>
							}>
								{name}
							</DialogTitle>

							<StyledDialogContent>
								<Image fit="contain" shape="rounded" src={api.getFileDownloadLink(encodeURIComponent(state.openedMedia))} onLoad={() => { setWorking(false); }} />
								{spinner}
							</StyledDialogContent>

							<StyledDialogActions position="start">
								<Button as='a' icon={<ArrowDownloadFilled/>} size={isTablet ? "medium" : "large"} onClick={(e) => { download(name) }}/>
								<Button as='a' icon={<ArrowExpand24Filled/>} size={isTablet ? "medium" : "large"} onClick={(e) => { download() }}/>
							</StyledDialogActions>
							<StyledDialogActions position="end">

								{beforePath ?
									<Button icon={<ChevronLeftFilled/>} size={isTablet ? "medium" : "large"} onClick={(e) => { setWorking(beforePath); setState(s =>({ ...s, openedMedia: beforePath})); }}/>
								:
									<Button icon={<ChevronLeftFilled/>} size={isTablet ? "medium" : "large"} disabled/>
								}

								{afterPath ?
									<Button icon={<ChevronRightFilled/>} size={isTablet ? "medium" : "large"} onClick={(e) => {  setWorking(afterPath); setState(s =>({ ...s, openedMedia: afterPath})); }} />
								:
									<Button icon={<ChevronRightFilled/>} size={isTablet ? "medium" : "large"} disabled/>
								}
							</StyledDialogActions>
						</DialogBody>
					</StyledDialogSurface>
				</Dialog>
			);
		}
		else
		{
			download();
			if (state.openedMedia != null)
			{
				setTimeout(() => {
					setState(s =>({ ...s, openedMedia: null}));
				}, 300);
			}
		}
	}
	else
	{
		if(!working)
			setWorking(true);
	}

	return null;
}

const StyledDialogSurface = styled(DialogSurface)`
	max-width: 90vw;
	height: 90vh;

	&>.fui-DialogBody {
		max-width: 90vw;
		height: calc(90vh - 48px);
	}
`;

const StyledDialogContent = styled(DialogContent)`
	display: flex;
	justify-content: center;
	position: relative;

	&>img{
		width: fit-content;
		height: fit-content;
		max-height: 100%;
		max-width: 100%;
		position: absolute;
		align-self: center;
	}
`;

const StyledSpinner = styled(Spinner)`
	position: absolute;
	align-self: center;
`;

const StyledDialogActions = styled(DialogActions)`
	flex-direction: row !important;
	justify-content: center;

	margin-bottom: 15px;
	gap: 40px;
	@media ${device.tablet} {
		margin-bottom: 0px;
		gap: inherit;
	}
`;