import React, { useState, useContext } from 'react';
import { ApiContext } from '../../api/ApiContext';

import {
	Dialog,
	DialogSurface,
	DialogTitle,
	DialogContent,
	DialogActions,
	DialogTrigger,
	DialogBody,
	Button,
	Select,
	Label,
	Input,
	Spinner,
  } from "@fluentui/react-components";

  import {
	CopyFilled
} from "@fluentui/react-icons";
import styled from 'styled-components';
import { NotificationContext } from '../../NotificationContext';

var editableRef = React.createRef();

export function ShareModal(props) {

	const api = useContext(ApiContext);
	const notifi = useContext(NotificationContext);

	const [link, setLink] = useState("");
	const [working, setWorking] = useState(false);

	var path = props.path;
	if(path !== null)
	{
		if(path.endsWith('/')) path = path.substring(0, path.length - 1);
		if(path.startsWith('/')) path = path.substring(1);
	}

	function close()
	{
		props.close();
	}

	function copy() {
		navigator.clipboard.writeText(link);
		notifi.success( `Zkopírováno do schránky`, 1500);
	}

	function newLink(e)
	{
		editableRef.current.disabled = true;
		e.target.disabled = true;
		setWorking(true);
		api.createShare(encodeURIComponent(path), editableRef.current.value, (result) => {
			setLink(window.location.origin + "/share/" + result);
			setWorking(false);
		}, () => {

		});
	}

	var spinner = null;
	if(working)
		spinner = <Spinner appearance="inverted" size="tiny"/>

	return (
		<Dialog open={path != null} onOpenChange={(event, data) => close()}>
			<DialogSurface>
				<DialogBody>
					<DialogTitle>Sdílet</DialogTitle>
					<StyledDialogContent>
						<div>
							<Label>Soubor: {path}</Label>
						</div>
						<div>
							<Label>Povolit editaci:</Label>
							<Select  ref={editableRef}>
								<option value="false">Ne</option>
								<option value="true">Ano</option>
							</Select>
						</div>
						<div>
							<Label style={{fontWeight: "600"}}>Odkaz:</Label><br/>
							<StyledLink>
								<Input type="url" appearance="filled-darker" value={link} readOnly/>
								<Button onClick={copy} icon={<CopyFilled/>}/>
							</StyledLink>
						</div>
					</StyledDialogContent>
					<DialogActions>
						<DialogTrigger disableButtonEnhancement>
							<Button appearance="secondary">Zavřít</Button>
						</DialogTrigger>
						<Button onClick={newLink} appearance="primary" icon={spinner}>Sdílet</Button>
					</DialogActions>
				</DialogBody>
			</DialogSurface>
		</Dialog>
	);
}

const StyledDialogContent = styled(DialogContent)`
	display: grid;
	gap: 12px;
`;

const StyledLink = styled.div`
	display: grid;
	grid-template-columns: 1fr auto;
`;
