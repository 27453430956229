import { useEffect, useState } from "react";

const size = {
	mobileS: '320px',
	mobileM: '375px',
	mobileL: '425px',
	tablet: '768px',
	laptop: '1024px',
	laptopL: '1440px',
	desktop: '2560px'
}

export const device = {
	mobileS: `(min-width: ${size.mobileS})`,
	mobileM: `(min-width: ${size.mobileM})`,
	mobileL: `(min-width: ${size.mobileL})`,
	tablet: `(min-width: ${size.tablet})`,
	laptop: `(min-width: ${size.laptop})`,
	laptopL: `(min-width: ${size.laptopL})`,
	desktop: `(min-width: ${size.desktop})`,
	desktopL: `(min-width: ${size.desktop})`
};

const useMediaDetector = (media) => {
	const getMatch = () => window.matchMedia(media).matches;
	const [isMatch, setisMatch] = useState(getMatch());
	const mqListener = (e => {
		setisMatch(e.matches);
	});

	useEffect(() => {
	  const mediaMq = window.matchMedia(media);
	  mediaMq.addEventListener("change", mqListener);
	  return () => mediaMq.removeEventListener(mqListener, ()=>{});
	}, [media]);
	return isMatch;
}

export default useMediaDetector;