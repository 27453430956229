import React, { useContext } from 'react';
import { Files } from '../components/Files/Files';
import { ApiContext } from '../api/ApiContext';
import { ApiFilesContext, ApiFilesContextProvider } from '../api/ApiFilesContext';


import {
	TabList,
	Tab,
} from "@fluentui/react-components";

import {
	ShareAndroidFilled,
} from "@fluentui/react-icons"

import Layout from './Layout';

function SharePage(props) {

	const api = useContext(ApiContext);
	const apiFiles = useContext(ApiFilesContext);

	var shareUrlStart = "/share";
	var sharePermaLinkLength = 9;

	var sharedPermaLink = null;
	if(window.location.pathname.startsWith(shareUrlStart))
	{
		sharedPermaLink = window.location.pathname.substring(shareUrlStart.length + 1,  shareUrlStart.length + sharePermaLinkLength);
	}

	if (apiFiles.api === null)
		apiFiles.api = api;

	if (apiFiles.permalink !== sharedPermaLink)
		apiFiles.permalink = sharedPermaLink;

	var sidebar = <>
		<TabList defaultSelectedValue={"files"} appearance="subtle" vertical>
			<Tab value="files" icon={<ShareAndroidFilled/>}>Sdílené soubory</Tab>
		</TabList>
	</>;

	var content = <>
		<ApiFilesContextProvider value={apiFiles}>
			<Files/>
		</ApiFilesContextProvider>
	</>;

	return (
		<Layout sidebar={sidebar} content={content}/>
	);
}


export default SharePage;


