import React, { useContext } from 'react';
import { ApiContext } from '../../api/ApiContext';


import { Button } from "@fluentui/react-components";
import { DeleteFilled, CopyFilled } from "@fluentui/react-icons";

import {
	TableCell,
	TableRow,
  } from "@fluentui/react-components/unstable";
import styled from 'styled-components';
import { NotificationContext } from '../../NotificationContext';

export function Share(props) {

	const api = useContext(ApiContext);
	const notifi = useContext(NotificationContext);

	function del() {
		api.removeShare(props.share.permaLink, (result) => {
			props.refresh();
		}, () => {

		});
	}

	function copy(link) {
		navigator.clipboard.writeText(link);
		notifi.success( `Zkopírováno do schránky`, 1500);
	}

	const link = window.location.origin + "/share/" + props.share.permaLink;
	const url = props.share.path;

	return (
		<TableRow>
			<TableCell><a href={link}>{props.share.permaLink}</a></TableCell>
			<TableCellURL>{url}</TableCellURL>
			<TableCell>{props.share.enableUpload ? "Ano" : "Ne"}</TableCell>
			<TableCell>
				<Button onClick={() => {copy(link)}} icon={<CopyFilled/>} appearance="subtle"/>
				<Button onClick={del} icon={<DeleteFilled />} appearance="subtle"/>
			</TableCell>
		</TableRow>
	);
}

const TableCellURL = styled(TableCell)`
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	max-width: 200px;
	text-align: left;
`;