import React, { useContext, useState }  from 'react';
import { ApiFilesContext } from '../../api/ApiFilesContext';
import { ShareModal } from '../Modals/ShareModal';

import styled from 'styled-components'

import { Button } from "@fluentui/react-components";

import { ShareAndroidFilled, EditFilled, DeleteFilled } from "@fluentui/react-icons";
import { Perm } from '../../pages/Perm';

export function FileUtilities(props) {

	const api = useContext(ApiFilesContext);

	const [sharePath, shareFile] = useState(null);

	function deleteFile(path)
	{
		var ok = window.confirm('Opravdu smazat?');
		if(ok)
		{
			api.deletefiles(encodeURIComponent(path), (result) => {
				props.refresh();
			}, () => {

			});
		}
	}

	function renameFile(path)
	{
		var newPath = window.prompt("Přejmenovat soubor", path);
		if(newPath)
		{
			api.moveFileOrFolder(encodeURIComponent(path), encodeURIComponent(newPath), (result) => {
				props.refresh();
			}, () => {

			});
		}
	}

	return (
		<Utils onClick={(e) => {e.stopPropagation();}} {...props}>
			<Perm subject="shareFile"><Button onClick={(e) => {shareFile(props.path);}} icon={<ShareAndroidFilled />} appearance="subtle"/></Perm>
			<Perm subject="renameFile"><Button onClick={(e) => {renameFile(props.path);}} icon={<EditFilled />} appearance="subtle"/></Perm>
			<Perm subject="deleteFile"><Button onClick={(e) => {deleteFile(props.path);}} icon={<DeleteFilled />} appearance="subtle"/></Perm>
			<ShareModal path={sharePath} close={()=>{shareFile(null)}}></ShareModal>
		</Utils>
	);
}


const Utils = styled.div`
	display: flex;
	gap: 6px;
`;
