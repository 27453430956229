import {  useContext, useState } from 'react';
import { ApiAuthContext } from '../api/ApiAuthContext';


import {
	Button,
	Input,
	Spinner
} from "@fluentui/react-components";

import { Card } from '@fluentui/react-components/unstable';
import styled from 'styled-components';

function LoginPage() {

	const apiAuth = useContext(ApiAuthContext);

	const [name, setName] = useState('');
	const [password, setPassword] = useState('');

	const [working, setWorking] = useState(false);

	function onLogin(e)
	{
		e.target.disabled = true;
		setWorking(true);
		apiAuth.login(name, password);
	}

	var spinner = null;
	if(working)
		spinner = <Spinner appearance="inverted" size="tiny"/>

	return(
		<StyledCard>
			<Input type="text" placeholder="Jméno" onChange={(e) => setName(e.target.value)} />
			<Input type="password" placeholder="Heslo" onChange={(e) => setPassword(e.target.value)} />
			<Button onClick={onLogin} appearance="primary" icon={spinner}>Přihlásit se</Button>
		</StyledCard>
	);
}

const StyledCard = styled(Card)`
	max-width: 500px;
	margin: auto;
	top: 20vh;
`;

export default LoginPage;
