import { ImageThumb } from "./FilesThumb";
import {
	DocumentRegular,
	FolderOpenFilled,
	FolderZipRegular,
	DocumentTextRegular,
	DocumentPdfRegular,
	ImageRegular,
	MusicNote2Regular,
	VideoRegular,
	AppGenericFilled
} from "@fluentui/react-icons";
import { CloudUploadIcon, ExcelDocumentIcon, PowerPointDocumentIcon, WordDocumentIcon } from '@fluentui/react-icons-mdl2-branded';


export function FileIcon(props)
{
	var icon = props.icon;

	if (icon === 'folder')
		return <FolderOpenFilled />;

	var mime = null;
	var useThumb = false;

	if(typeof(icon) === "string")
	{
		mime = icon;
	}
	else if(icon != null)
	{
		mime = icon.icon;
		useThumb = true;
	}

	if(mime != null)
	{
		var parts = mime.split('/');
		switch (parts[0]) {
			case 'audio': return <MusicNote2Regular />;
			case 'image': return useThumb ? <ImageThumb mime={mime} url={icon.path} /> : <ImageRegular />;
			case 'video': return <VideoRegular />;
			case 'text': return <DocumentTextRegular />;
			case 'upload': return <CloudUploadIcon />;

			default:
				if(parts[1].indexOf('spreadsheetml') !== -1) return <ExcelDocumentIcon />;
				if(parts[1].indexOf('pdf') !== -1) return <DocumentPdfRegular />;
				if(parts[1].indexOf('presentationml') !== -1) return <PowerPointDocumentIcon />;
				if(parts[1].indexOf('wordprocessingml') !== -1) return <WordDocumentIcon />;
				if(parts[1].indexOf('zip') !== -1) return <FolderZipRegular />;
				if(parts[1].indexOf('executable') !== -1) return <AppGenericFilled />;
		}
	}

	return <DocumentRegular />;
}