import React, { useState, useEffect, useContext, useCallback }  from 'react';
import { ApiContext } from '../../api/ApiContext';
import { Share } from './Share';

import {
	Spinner
} from "@fluentui/react-components";
import {
	TableBody,
	TableRow,
	Table,
	TableHeader,
	TableHeaderCell,
} from "@fluentui/react-components/unstable";

export function Sharing(props) {

	const api = useContext(ApiContext);

	const [state, setState] = useState({
		shares: null,
	});

	const [working, setWorking] = useState(false);

	const refresh = useCallback(() => {
		setWorking(true);
		api.getShares((shares) => {
			setWorking(false);
			setState(s => ({...s, shares}));
		}, () => {

		});
	}, [api]);

	useEffect(() => {
		refresh();
	}, [refresh]);

	const elements = [];

	for (const key in state.shares) {
		const element = state.shares[key];
		elements.push(<Share key={key} share={element} refresh={refresh}/>);
	}

	return (
		<div>
			<h2>Sdílení</h2>

			{working ?
				<Spinner size="huge"/>
			:
				<Table>
					<TableHeader>
						<TableRow>
							<TableHeaderCell>Link</TableHeaderCell>
							<TableHeaderCell>Cesta</TableHeaderCell>
							<TableHeaderCell>Editace</TableHeaderCell>
							<TableHeaderCell>Akce</TableHeaderCell>
						</TableRow>
					</TableHeader>
					<TableBody>
						{elements}
					</TableBody>
				</Table>
			}
		</div>
	);
}