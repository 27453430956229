import React, { useContext, useState }  from 'react';
import DefaultPage from './pages/DefaultPage';
import SharePage from './pages/SharePage';
import LoginPage from './pages/LoginPage';
import { ApiContext } from './api/ApiContext';
import { FluentProvider, webDarkTheme, webLightTheme } from '@fluentui/react-components';
import Route from './pages/Router/Route';
import { ApiAuthContext, ApiAuthContextProvider } from './api/ApiAuthContext';
import useMediaDetector, { device } from './MediaDetector';
import { Alert } from '@fluentui/react-components/unstable';
import {
	DismissCircleFilled
} from "@fluentui/react-icons";
import styled from 'styled-components';
import { NotificationContext, NotificationContextProvider } from './NotificationContext';

function App() {
	const [user, setUser] = useState(null);

	const api = useContext(ApiContext);
	const apiAuth = useContext(ApiAuthContext);
	const notifi = useContext(NotificationContext);

	if(!api.auth && !api.notifi)
	{
		api.notifi = notifi;
		api.auth = apiAuth;
	}

	apiAuth.api = api;
	apiAuth.user = user;
	apiAuth.setUser = setUser;
	apiAuth.loadUser();

	const isDarkTheme = useMediaDetector('(prefers-color-scheme: dark)');

	return (
		<FluentProvider theme={isDarkTheme ? webDarkTheme : webLightTheme}>
			<NotificationContextProvider value={notifi}>
				<ApiAuthContextProvider value={apiAuth}>
					<Route pathStart="/share">
						<SharePage/>
					</Route>
					<Route pathStart="/share" not={true}>
						<Content />
					</Route>
				</ApiAuthContextProvider>
				<NotificationArea />
			</NotificationContextProvider>
		</FluentProvider>
	) ;
}

function Content() {
	const apiAuth = useContext(ApiAuthContext);

	if(apiAuth.getCurrentUser())
		return <DefaultPage />;
	else
		return <LoginPage />;
}

function NotificationArea()
{
	const notifi = useContext(NotificationContext);
	const [notifications, setNotifications] = useState([]);
	notifi.setNotifications = setNotifications;

	function remove(time) {
		setNotifications((s) =>
			s.filter((n) => n.time !== time)
		);
	}

	return (
		<AlertBox>
			{notifications.map((notification, index) => (
				<Alert key={index} intent={notification.intent} action={{icon: <DismissCircleFilled onClick={() => { remove(notification.time)} }/>}}>
					{notification.text}
				</Alert>
			))}
		</AlertBox>
	);
}

export default App;

const AlertBox = styled.div`
	position: fixed;
	z-index: 9999999;

	width: 90vw;
    padding-left: 5vw;
    padding-right: 5vw;
	bottom: 16px;

	display: flex;
	flex-direction: column;
	gap: 10px;

	@media ${device.laptop} {
		width: 60vw;
		padding-left: 20vw;
		padding-right: 20vw;
		bottom: 128px;
	}
`;
