import React from 'react';

export class Notification {

	setNotifications = null;

	newNotification(intent, msg, duration)
	{
		var newNotification = {
			intent: intent,
			text: msg,
			time: new Date(),
			duration: duration,
		};

		this.setNotifications(s => ([ ...s, newNotification]));

		if(!duration)
			duration = 5000;

		setTimeout(() => {
			this.setNotifications((s) =>
				s.filter((n) => n.time !== newNotification.time)
			);
		}, duration);
	}

	success(msg, duration)
	{
		this.newNotification("success", msg, duration);
	}

	warning(msg, duration)
	{
		this.newNotification("warning", msg, duration);
	}

	error(msg, duration)
	{
		this.newNotification("error", msg, duration);
	}

	info(msg, duration)
	{
		this.newNotification("info", msg, duration);
	}
}

export const NotificationContext = React.createContext(new Notification());
export const NotificationContextProvider = NotificationContext.Provider;