import React, { useState, useEffect, useContext, useCallback }  from 'react';
import { ApiContext } from '../../api/ApiContext';
import { UserRow } from './UserRow';
import { UserModal } from '../Modals/UserModal';

import {
	Button, Spinner
} from "@fluentui/react-components";

import {
	TableBody,
	TableRow,
	Table,
	TableHeader,
	TableHeaderCell,
} from "@fluentui/react-components/unstable";

import { PersonAddFilled } from "@fluentui/react-icons";

export function Users(props) {

	const api = useContext(ApiContext);

	const [state, setState] = useState({
		users: null,
		modalShow: false,
	});

	const [working, setWorking] = useState(false);

	const refresh = useCallback(() => {
		setWorking(true);
		api.getUsers(null, (users) => {
			setWorking(false);
			setState(s => ({...s, users}));
		}, () => {

		});
	}, [api]);

	useEffect(() => {
		refresh();
		return () => {};
	}, [refresh]);

	function setAddModal(modalShow) {
		setState(s => ({...s, modalShow}));
	}

	const elements = [];

	for (const key in state.users) {
		const element = state.users[key];
		elements.push(<UserRow key={element.name} user={element} refresh={refresh}/>);
	}

	return (
		<div>
			<h2>Uživatelé</h2>
			<div>
				<Button onClick={()=>{setAddModal(true);}} icon={<PersonAddFilled/>}> Přidat uživatele</Button>
			</div>
			{working ?
				<Spinner size="huge"/>
			:
				<Table>
					<TableHeader>
						<TableRow>
							<TableHeaderCell scope="col">Jméno</TableHeaderCell>
							<TableHeaderCell scope="col">Admin</TableHeaderCell>
							<TableHeaderCell scope="col">Url</TableHeaderCell>
							<TableHeaderCell scope="col">Akce</TableHeaderCell>
						</TableRow>
					</TableHeader>
					<TableBody>
						{elements}
					</TableBody>
				</Table>
			}
			<UserModal open={state.modalShow} close={() => {setAddModal(false)}} refresh={refresh}/>
		</div>
	);
}
