import React, { useContext } from 'react';
import Link from './Router/Link';
import { ApiAuthContext } from '../api/ApiAuthContext';

import { Persona } from '@fluentui/react-components';
import { Card } from '@fluentui/react-components/unstable';
import styled from 'styled-components';
import { device } from '../MediaDetector';

function Layout(props) {

	const apiAuth = useContext(ApiAuthContext);
	const user = apiAuth.getCurrentUser();

	return (
		<LayoutRoot>
			<LeftBar>
				<div>
					<Link href="/"><Logo src={Math.random() < 0.5 ? "/svg/Happy_Cloud.svg" : "/svg/Cute_Cloud.svg" }  alt="" /></Link>
				</div>
				{user !== null ?
				<TabListCard>
					<Persona
						size="extra-large"
						name={user.userName}
						secondaryText={user.isAdmin ? "Admin" : "Uživatel"}
					/>
				</TabListCard>
				: null}
				<TabListCard>
					{props.sidebar}
				</TabListCard>
			</LeftBar>
			<Content>
				{props.content}
			</Content>
		</LayoutRoot>
	);
}

const Logo = styled.img`
	max-height: 150px;
	margin: auto;
	display: block;
`;

const LayoutRoot = styled.div`
	display: grid;
	gap: 16px 32px;
	padding: 32px;

	@media ${device.laptop} {
		grid-template-columns: 300px auto;
	}
`;

const LeftBar = styled.div`
	@media ${device.laptop} {
		grid-column: 1 / 2;
	}
`;

const TabListCard = styled(Card)`
	margin-top: 16px;
`;

const Content = styled(Card)`
	padding: 10px;
	margin-left: -30px;
	margin-right: -30px;

	@media ${device.laptop} {
		grid-column: 2 / 3;
		padding: 32px;
		margin: 0px;
	}

	padding-top: 16px;
`;

export default Layout;


