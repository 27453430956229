 import {
	ImageRegular,
} from "@fluentui/react-icons";

import { Image } from "@fluentui/react-components";
import { useContext, useEffect, useState } from "react";
import { ApiFilesContext } from "../../api/ApiFilesContext";
import styled from "styled-components";

export function ImageThumb(props)
{
	const api = useContext(ApiFilesContext);

	const [imageSrc, setImageSrc] = useState("");

	const [working, setWorking] = useState(true);

	useEffect(() => {
		var link = api.getFileThumbLink(encodeURIComponent(props.url));
		setImageSrc(link);
	}, [api, props.url]);

	switch (props.mime) {
		case "image/bmp":
		case "image/gif":
		case "image/jpeg":
		case "image/png":
			if (imageSrc)
				return (
				<ImageContainer>
					{working ? <ImageRegular /> : null}
					<Image fit="contain" shape="rounded" src={imageSrc} onLoad={() => {setWorking(false)}}/>
				</ImageContainer>
				);
			else
				return <ImageRegular />;
		default:
			return <ImageRegular />;
	}
}

const ImageContainer = styled.div`
	min-height: 90px;

	display: flex;
	justify-content: center;

	&>img{
		z-index: 1;
	}

	&>i:before, &>svg{
		align-self: center;
		height: 50px;
		width: 50px;
		line-height: 50px;
		position: absolute;
	}
`;
