import React from 'react';
import { changeUrl } from '../../pages/Router/Link';
import { FileIcon } from './FilesIcon';
import { FileUtilities } from './FileUtilities';

import styled from 'styled-components'

import {
	Button,
	Link as FUILink,
} from "@fluentui/react-components";

import {
	DismissFilled
} from "@fluentui/react-icons";

import { Card } from '@fluentui/react-components/unstable';
import { device } from '../../MediaDetector';

export function FilesGrid(props) {

	const state = props.state;
	const setState = props.setState;
	const refresh = props.refresh;

	const items = [];

	for (const key in state.uploadingFiles) {
		const element = state.uploadingFiles[key];
		items.push({
			name: element.name,
			path: "",
			icon: "upload",
			uploaded: "",
			size: Math.round(element.progress) + "%",
			type: "upload",
			delete: () => {
				element.abort();
			}
		});
	}

	for (const key in state.folderContent) {
		const element = state.folderContent[key];
		var path = `${state.folderPath}${element.name}`;
		items.push({
			name: element.name,
			path: path,
			icon: element.mime,
			uploaded: "",
			size: "",
			type: element.type,
		});
	}

	return (
		<Content>
			{items.map((item) =>{
				return (
					item.type === "folder" ?
						<File key={item.name} onClick={() =>{ changeUrl(`${state.urlRoot}/${item.path}`); }}>
							<FUILink as='button'>{item.name}</FUILink>
							<FileIcon icon="folder"/>
							<FileUtilities path={item.path} refresh={refresh} className="btns"/>
						</File>
					: item.type === "upload" ?
						<File key={item.name}>
							<FUILink as='button'>{item.name}</FUILink>
							<FileIcon icon="upload"/>
							{item.size}
							<div className="btns">
								<Button onClick={(e) => {item.delete();}} icon={<DismissFilled/>} appearance="subtle"/>
							</div>
						</File>
					:
						<File key={item.name} onClick={(e) => {e.stopPropagation(); setState(s => ({ ...s, openedMedia: item.path }))}}>
							<FUILink as='button'>{item.name}</FUILink>
							<FileIcon icon={item}/>
							<FileUtilities path={item.path} refresh={refresh} className="btns"/>
						</File>
				);
			})}
		</Content>
	);
}

const Content = styled.div`
	display: flex;
	flex-wrap: wrap;
	gap: 6px;
	margin-top: 12px;
`;

const File = styled(Card)`
	width: 190px;
	height: 190px;
	display: grid;
	grid-template-rows: 36px 1fr 24px;
	justify-items: center;
	word-break: break-word;

	&>button{
		width: 100%;
		text-align: center;
	}

	&>i:before, &>svg{
		align-self: center;
		height: 50px;
		width: 50px;
		line-height: 50px;
	}

	&>span {
		align-self: center;
		height: 40px;
		width: 40px;
		line-height: 40px;
	}

	@media ${device.laptop} {
		width: 200px;
		height: 200px;

		&:not(:hover) div.btns {
			display: none;
		}
	}
`;