import { useContext } from "react";
import { ApiAuthContext } from "../api/ApiAuthContext";
import { ApiFilesContext } from "../api/ApiFilesContext";

var userPermArr = [ 'files', 'sharing', 'newFolder', 'shareFile', 'uploadaFile', 'renameFile', 'deleteFile' ];
var anonPermArr = [ 'newFolder', 'uploadaFile', 'renameFile', 'deleteFile' ];

export function Perm(props) {
	var files = useContext(ApiFilesContext);
	const apiAuth = useContext(ApiAuthContext);
	var user = apiAuth.getCurrentUser();

	var permitted = false;

	if(files !== null && files.permalink)
	{
		if(files.editable)
			permitted = anonPermArr.includes(props.subject);
	}
	else
	{
		if (user !== null)
		{
			if (user.isAdmin)
				permitted = true;
			else
				permitted = userPermArr.includes(props.subject);
		}
	}
	
	if (permitted)
		return props.children;
	else if(props.alt !== null)
		return props.alt;
	else
		return null;
}