import React from 'react';

class Api {

	baseUrl = '/api';
	auth = null;
	notifi = null;

	async request(method, url, body)
	{
		var headers = {'Content-Type': 'application/json'};

		var rqst = { method: method, headers: headers };
		if(body)
		  rqst.body = JSON.stringify(body);

		const response = await fetch(url, rqst).catch((er) => {
			console.log(er);
			this.notifi.error( `API ERROR: ${er}`);
			return null;
		});

		if(response && response.ok)
		{
			return response;
		}
		else
		{
			this.notifi.error(`API ERROR ${response.status}: ${response.statusText} | ${response.url}`);
			if (response.status === 401)
				this.auth.clearUser();
			return null;
		}
	}

	async requestJson(method, url, body)
	{
		return await this.request(method, url, body).then((response) => {
			if (response)
				return response.json();
			else
				return null;
		});
	}

	async requestTxt(method, url, body)
	{
		return await this.request(method, url, body).then((response) => {
			if (response)
				return response.text();
			else
				return null;
		});
	}

	async requestBin(method, url, body)
	{
		return await this.request(method, url, body).then((response) => {
			if (response)
				return response.body;
			else
				return null;
		});
	}

	//Files
	getfiles(link, callback, errorCallback)
	{
		this.requestJson('GET', `${this.baseUrl}/files/files/${link}`, null).then((result) => {
			if (result !== null)
				callback(result);
			else
				errorCallback();
		});
	}

	deletefiles(link, callback, errorCallback)
	{
		this.requestTxt('DELETE', `${this.baseUrl}/files/file/${link}`, null).then((result) => {
			if (result !== null)
				callback(result);
			else
				errorCallback();
		});
	}

	createFolder(link, callback, errorCallback)
	{
		this.requestTxt('POST', `${this.baseUrl}/files/folder/${link}`, null).then((result) => {
			if (result !== null)
				callback(result);
			else
				errorCallback();
		});
	}

	moveFileOrFolder(link, newLink, callback, errorCallback)
	{
		this.requestTxt('PUT', `${this.baseUrl}/files/file/${link}/${newLink}`, null).then((result) => {
			if (result !== null)
				callback(result);
			else
				errorCallback();
		});
	}

	getFileDownloadLink(link)
	{
		return `${this.baseUrl}/files/file/${link}`;
	}

	getFileThumbLink(link)
	{
		return `${this.baseUrl}/files/thumb/${link}`;
	}

	uploadFiles(link, file, progress, callback, errorCallback)
	{
		var body = new FormData();
		body.append('postedFile', file);

		var xhr = new XMLHttpRequest();
		xhr.open('POST', `${this.baseUrl}/files/file/${link}`);

		xhr.upload.addEventListener('progress', (e) => {
			progress(e.loaded, e.total, abort);
		});

		xhr.addEventListener('load', ()=>{
			callback(xhr.status, xhr.response);
		});

		xhr.send(body);

		function abort()
		{
			xhr.abort();
			callback(xhr.status, xhr.response);
		}
	}

	//Sharing
	getShares(callback, errorCallback)
	{
		this.requestJson('GET', `${this.baseUrl}/shared/shares`, null).then((result) => {
			if (result !== null)
				callback(result);
			else
				errorCallback();
		});
	}

	createShare(link, enableUpload, callback, errorCallback)
	{
		this.requestTxt('POST', `${this.baseUrl}/shared/share/${link}` + (enableUpload === "true" ? `?enableUpload=true` : `?enableUpload=false`), null).then((result) => {
			if (result !== null)
				callback(result);
			else
				errorCallback();
		});
	}

	removeShare(permaLink, callback, errorCallback)
	{
		this.requestTxt('DELETE', `${this.baseUrl}/shared/share/${permaLink}`, null).then((result) => {
			if (result !== null)
				callback(result);
			else
				errorCallback();
		});
	}

	getSharedFiles(permaLink, link, callback, errorCallback)
	{
		this.requestJson('GET', `${this.baseUrl}/shared/share/${permaLink}/files/${link}`, null).then((result) => {
			if (result !== null)
				callback(result);
			else
				errorCallback();
		});
	}

	deleteSharedFile(permaLink, link, callback, errorCallback)
	{
		this.requestTxt('DELETE', `${this.baseUrl}/shared/share/${permaLink}/file/${link}`, null).then((result) => {
			if (result !== null)
				callback(result);
			else
				errorCallback();
		});
	}

	createSharedFolder(permaLink, link, callback, errorCallback)
	{
		this.requestTxt('POST', `${this.baseUrl}/shared/share/${permaLink}/folder/${link}`, null).then((result) => {
			if (result !== null)
				callback(result);
			else
				errorCallback();
		});
	}

	moveSharedFile(permaLink, link, newLink, callback, errorCallback)
	{
		this.requestTxt('PUT', `${this.baseUrl}/shared/share/${permaLink}/file/${link}/${newLink}`, null).then((result) => {
			if (result !== null)
				callback(result);
			else
				errorCallback();
		});
	}

	getSharedDownloadLink(permaLink, link)
	{
		return `${this.baseUrl}/shared/share/${permaLink}/file/${link}`;
	}

	getSharedThumbLink(permaLink, link)
	{
		return `${this.baseUrl}/shared/share/${permaLink}/thumb/${link}`;
	}

	uploadSharedFile(permaLink, link, file, progress, callback, errorCallback)
	{
		var body = new FormData();
		body.append('postedFile', file);

		var xhr = new XMLHttpRequest();
		xhr.open('POST', `${this.baseUrl}/shared/share/${permaLink}/file/${link}`);

		xhr.upload.addEventListener('progress', (e) => {
			progress(e.loaded, e.total, abort);
		});

		xhr.addEventListener('load', ()=>{
			callback(xhr.status, xhr.response);
		});

		xhr.send(body);

		function abort()
		{
			xhr.abort();
			callback(xhr.status, xhr.response);
		}
	}


	//Users
	getUsers(id, callback, errorCallback)
	{
		this.requestJson('GET', `${this.baseUrl}/users/` + (id ? `?id=${id}` : ``), null).then((result) => {
			if (result !== null)
				callback(result);
			else
				errorCallback();
		});
	}

	setUser(id, name, password, defaultfolder, isAdmin, callback, errorCallback)
	{
		this.requestTxt('PUT', `${this.baseUrl}/users/user`, {
			id: id ? id : null,
			name: name,
			password: password,
			defaultFolder: defaultfolder,
			isAdmin: isAdmin === true,
		}).then((result) => {
			if (result !== null)
				callback(result);
			else
				errorCallback();
		});
	}

	removeUser(id, callback, errorCallback)
	{
		this.requestTxt('DELETE', `${this.baseUrl}/users/user/${id}`, null).then((result) => {
			if (result !== null)
				callback(result);
			else
				errorCallback();
		});
	}

	//Token
	login(name, pass, callback, errorCallback)
	{
		this.requestJson('POST', `${this.baseUrl}/auth/login`, {
			Name: name,
			Password: pass
		}).then((result) => {
			if (result !== null)
				callback(result);
			else
				errorCallback();
		});
	}

	logout(callback, errorCallback)
	{
		this.requestTxt('GET', `${this.baseUrl}/auth/logout`, null).then((result) => {
			if (result !== null)
				callback(result);
			else
				errorCallback();
		});
	}
}

export const ApiContext = React.createContext(new Api());
export const ApiContextProvider = ApiContext.Provider;