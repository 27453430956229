import React, { useState, useEffect, useContext, useCallback }  from 'react';
import { ApiFilesContext } from '../../api/ApiFilesContext';
import { FilesHeader } from './FilesHeader';
import { FilesList } from './FilesList';
import { FilesGrid } from './FilesGrid';

import {
	Spinner
} from "@fluentui/react-components";
import { MediaModal } from '../Modals/MediaModal';

function isMostlyPictures(files) {
	var total = 0;
	var pictures = 0;

	for (const key in files) {
		var file = files[key];
		if (file.type === 'file')
		{
			total++;
			if (file.mime && file.mime.startsWith('image'))
				pictures++;
		}
	}

	return (total / 2) < pictures;
}

export function Files(props) {

	const api = useContext(ApiFilesContext);

	var filesUrlStart = "/files";
	var shareUrlStart = "/share";
	var sharePermaLinkLength = 9;

	const [state, setState] = useState({
		urlRoot: null,
		folderPath: null,
		folderContent: null,
		displayTypeGrid: false,
		uploadingFiles: {},
		selectedItems: new Set([]),
		openedMedia: null
	});

	const [working, setWorking] = useState(false);

	const changeFolder = useCallback((path, silent) => {
		if(path != null)
		{
			if(!path.endsWith('/')) path = path + '/';
			if(path.startsWith('/')) path = path.substring(1);

			setState(s => ({...s, folderPath: path}));

			if(!silent)
				setWorking(true);

			api.getfiles(encodeURIComponent(path), (files) => {
				setWorking(false);
				setState(s => ({...s, folderContent: files, selectedItems: new Set([]), displayTypeGrid: isMostlyPictures(files)}));
			}, () => {

			});
		}
	}, [api]);

	const onLocationChange = useCallback(() => {
		if(window.location.pathname.startsWith(filesUrlStart))
		{
			setState(s => ({...s, urlRoot: window.location.pathname.substring(0, filesUrlStart.length)}));
			changeFolder(decodeURIComponent(window.location.pathname.substring(filesUrlStart.length)));
		}

		if(window.location.pathname.startsWith(shareUrlStart))
		{
			setState(s => ({...s, urlRoot: window.location.pathname.substring(0, shareUrlStart.length + sharePermaLinkLength)}));
			changeFolder(decodeURIComponent(window.location.pathname.substring(shareUrlStart.length + sharePermaLinkLength)));
		}
	}, [changeFolder, filesUrlStart, shareUrlStart, sharePermaLinkLength]);

	useEffect(() => {
		window.addEventListener('popstate', onLocationChange);
		onLocationChange();

		return () => {
			window.removeEventListener('popstate', onLocationChange)
		};
	}, [onLocationChange]);

	function refresh(silent)
	{
		changeFolder(state.folderPath, silent);
	}

	return (
		<div>
			<FilesHeader state={state} setState={setState} refresh={refresh} />
			{working ?
				<Spinner size="huge"/>
			: state.displayTypeGrid ?
				<FilesGrid state={state} setState={setState} refresh={refresh} />
			:
				<FilesList state={state} setState={setState} refresh={refresh} />
			}
			<MediaModal state={state} setState={setState} />
		</div>
	);
}