import React from 'react';

class ApiAuth {

	api = null;
	user = null;
	setUser = null;

	loadUser()
	{
		var storedUser = JSON.parse(localStorage.getItem("user"));
		if(!this.user && storedUser)
			this.setUser(storedUser);
	}

	login(name, pass)
	{
		this.api.login(name, pass, (result) =>{
			localStorage.setItem("user", JSON.stringify(result));
			this.setUser(result);
		}, () => {

		});
	}

	logout()
	{
		this.api.logout((result) =>{
			this.clearUser();
		}, () => {

		});
	}

	clearUser()
	{
		localStorage.removeItem("user");
		this.setUser(null);
	}

	getCurrentUser()
	{
		return this.user;
	}
}

export const ApiAuthContext = React.createContext(new ApiAuth());
export const ApiAuthContextProvider = ApiAuthContext.Provider;