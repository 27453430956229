import React, { useContext } from 'react';
import Route from './Router/Route';
import Layout from './Layout';
import { changeUrl } from './Router/Link';
import { ApiContext } from '../api/ApiContext';
import { ApiAuthContext } from '../api/ApiAuthContext';
import { ApiFiles, ApiFilesContext, ApiFilesContextProvider } from '../api/ApiFilesContext';
import { Files } from '../components/Files/Files';
import { Sharing } from '../components/Sharing/Sharing';
import { Users } from '../components/Users/Users';
import { Settings } from '../components/Settings/Settings';

import {
	TabList,
	Tab,
} from "@fluentui/react-components";

import {
	DocumentBulletListFilled,
	ShareAndroidFilled,
	PeopleTeamFilled,
	SettingsFilled,
	SignOutFilled
} from "@fluentui/react-icons"
import { Perm } from './Perm';


function DefaultPage(props) {

	const api = useContext(ApiContext);
	const apiAuth = useContext(ApiAuthContext);
	const apiFiles = useContext(ApiFilesContext);

	if (apiFiles.api === null)
		apiFiles.api = api;

	if (apiFiles.permalink !== null)
		apiFiles.permalink = null;

	function onTabSelect(e, tab) {
		if(tab.value === "logout")
			apiAuth.logout();
		else
			changeUrl(`/${tab.value}`);
	}

	var selectedTab = "";

	if(Route({pathStart:"/files"})) selectedTab = "files";
	if(Route({path:"/sharing"})) selectedTab = "sharing";
	if(Route({path:"/users"})) selectedTab = "users";
	if(Route({path:"/settings"})) selectedTab = "settings";

	var sidebar = <>
		<TabList defaultSelectedValue={selectedTab} onTabSelect={onTabSelect} appearance="subtle" vertical>
			<Perm subject="files"><Tab value="files" icon={<DocumentBulletListFilled/>}>Soubory</Tab></Perm>
			<Perm subject="sharing"><Tab value="sharing" icon={<ShareAndroidFilled/>}>Sdílení</Tab></Perm>
			<Perm subject="users"><Tab value="users" icon={<PeopleTeamFilled/>}>Uživatelé</Tab></Perm>
			<Perm subject="settings"><Tab value="settings" icon={<SettingsFilled/>}>Nastavení</Tab></Perm>
			<Tab value="logout" icon={<SignOutFilled/>}>Odhlásit</Tab>
		</TabList>
	</>;

	var content = <>
		<Route pathStart="/files">
			<ApiFilesContextProvider value={apiFiles}>
				<Files/>
			</ApiFilesContextProvider>
		</Route>
		<Route path="/sharing"><Sharing/></Route>
		<Route path="/users"><Users/></Route>
		<Route path="/settings"><Settings/></Route>
	</>;

	return (
		<Layout sidebar={sidebar} content={content}/>
	);
}

export default DefaultPage;


